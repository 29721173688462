/* App.css */
.dc-split-container {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: flex-start;
    padding: 20px 20px 20px 4rem;
    padding-top: 6rem;
  }
   
  .dc-split-text {
    flex: 1;
    padding: 2rem 6rem 3rem 6rem;
  }

  .dc-split-head{
    text-align: center;
    margin-bottom: 2rem;
    color: rgb(43, 74, 83);
  }

  .dc-split-desc{
    font-size: 1.09rem;
    line-height: 1.8rem;
    font-family: sans-serif;
    color: rgb(105, 105, 105);
  }
  
  .dc-split-image {
    flex: 1;
    max-width: 100%;
  }
  
  .dc-split-image img {
    width: 100%; /* Make image responsive */
    /* height: auto; */
    height: 460px;
    border-radius: 20px 0 20px 0;
  }
  
  /* Make the layout responsive */
  @media (max-width: 768px) {
    .dc-split-container {
      flex-direction: column;
      align-items: center;
      padding: 1rem;
    }

    .dc-split-text, .dc-split-image {
      flex: none;
      width: 100%;
      padding: 0;
    }
  }
  