.contact-top{
    min-height: 340px;
    position: relative;
    top: 86px;
    background-color: #FF3CAC;
    /* background-image: linear-gradient(225deg, #FF3CAC 0%, #784BA0 50%, #2B86C5 100%);        */
    background-image: linear-gradient(225deg, #0e1b46 0%, #2a2461 50%, #3a2bc5 100%);       
}

.contact-container{
    padding: 2rem 2rem 1rem;
}

.contact-container > h4{
    text-align: center;
    margin: 0rem 0 2rem 0;
    color: white;
    font-size: xx-large;
}


.contact-us-en{
    padding: 10px;
    border: none;
    border-radius: 10px;
    max-width: 320px;
    min-width: 320px;
    &:focus{
        outline: none;
        border-color: white; 
        box-shadow: 0 0 4px 1px rgb(0, 0, 0); 
    }
}

.contact-sect-one{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    column-gap: 1rem;
    row-gap: 2rem;
    margin-bottom: 2rem;
}

.contact-sect-two{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    column-gap: 1rem;
    row-gap: 2rem;
    margin-bottom: 1rem;
}

.contact-us-msg{
    padding: 10px;
    border: none;
    border-radius: 10px;
    max-width: 656px;
    min-width: 656px;
    height: 80px;
    &:focus{
        outline: none;
        border-color: white; 
        box-shadow: 0 0 4px 1px rgb(15, 15, 15); 
    }
}

.contact-us-btn{
    box-shadow: 0 0 2px 2px grey;
    border: none;
    border-radius: 30PX;
    color: #000000;
    padding: 0.8rem 1rem;
    background: #ffffff;
    cursor: pointer;
    &:hover{
        box-shadow: 0px 0px 2px 2px rgb(51, 51, 51);
    }
}


@media screen and (max-width : 720px) {
    .contact-sect-one{
        flex-direction: column;
    }

    .contact-us-en, .contact-us-msg{
        min-width: 100%;
    }
}