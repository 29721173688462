

.db-blogs-articles-sect{
    position: relative;
    top: 86px;
    min-height: 600px;
    background-color: rgb(255, 255, 255);
    background: url(../../Images/Blogs/blogsbg.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    z-index: 10;
}

.db-blogs-articles-sect::before{
    content: '';
    position: absolute;
    inset: 0;
    background: linear-gradient(to right, rgba(142, 142, 235, 0.432), rgba(58, 58, 207, 0.432));
    /* z-index: 12; */
}

.db-blogs-articles-wrap{
    position: relative;
    z-index: 14;
}

.db-blogs-sect{
    padding: 2% 3%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.db-blogs-articles-head{
    font-size: 2rem;
    font-weight: bold;
    font-family: sans-serif;
    padding: 2rem 0;
    text-align: center;
    color: white;
}

.db-blog-wrap{
    padding: 2% 2%;
    min-width: 240px;
    max-width: 280px;
    min-height: 300px;
    max-height: 320px;
    background: rgb(255, 255, 255);
    /* border: 1px solid black; */
    box-shadow: 0px 1px 3px 1px #1b1b1b;
    transition: transform 0.3s ease-in;
}

.db-blog-wrap > img{
    width: 80%;
    height: 80%;
    text-align: center;
}

.db-blog-head{
    font-family: sans-serif;
    font-size: 1.4rem;
    font-weight: 600;
    padding: 0.8rem;
    color: rgb(41, 41, 41);
    /* border-bottom: 2px solid rgb(136, 136, 136); */
    /* text-align: center; */
}




.db-pdf-viewer{
    position: absolute;
    inset: 0;
    background-color: #1b1b1b;
    z-index: 1000;
    text-align: center;
}

.db-pdf-sect{
    position: relative;
    top: 4%;
    width: 80%;
    height: 90%;
}

.db-pdf-head{
    position: absolute;
    top: 2%;
    right: 2%;
    z-index: 1002;
    width: 40px;
    height: 40px;
    color: white;
    font-size: 1.6rem;
    font-weight: 600;
    border: 2px solid white;
    border-radius: 20%;
    transition: all 0.4s ease-in;
    cursor: pointer;
    &:hover{
        background-color: white;
        color: rgb(0, 0, 0);
    }
}