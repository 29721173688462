@import url('https://fonts.googleapis.com/css2?family=Archivo:ital,wght@0,100..900;1,100..900&family=Bakbak+One&family=Barlow+Condensed:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Bebas+Neue&family=Fjalla+One&family=Goldman:wght@400;700&family=Koulen&family=Monomaniac+One&family=Rubik+Mono+One&family=Signika+Negative:wght@300..700&family=Source+Code+Pro:ital,wght@0,200..900;1,200..900&family=Space+Grotesk:wght@300..700&display=swap');

    a {
        text-decoration: none;
    }

    .bc-wrapper{
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        row-gap: 1rem;
        column-gap: 2rem;
        flex-wrap: wrap;
        z-index: 6;
        margin-bottom: 2rem;
    }


    .bc-head{
        text-align: center;
        font-family: "Archivo", sans-serif;
        font-size: larger;
        letter-spacing: 1.2px;
        margin: 3rem;
        color: rgb(75, 75, 75);
    }

    .bc-head-comp-name{
        font-family: 'Goldman', sans-serif;
        font-weight: 500;
        background: linear-gradient(to right, rgb(158, 44, 44) , rgb(37, 37, 189));
        background-clip: text;
        color: transparent;
    }



    ol { 
        counter-reset: li; 
        list-style: none; 
        padding: 0;
        text-shadow: 0 1px 0 rgba(255,255,255,.5);
        margin-top: 2rem;
    }

    ol a {
        position: relative;
        display: block;
        padding: .4em .4em .4em 2em;
        margin: .5em 0;
        background: #f9f9f9;
        color: #444;
        text-decoration: none;
        border-radius: .3em;
        transition: .3s ease-out;
        /* width: 60%; */
        
/* left: 20%; */
    }

    li {
        width: 60%;
        margin: auto;
    }
    ol a:before {
        content: counter(li);
        counter-increment: li;
        position: absolute;
        left: -1.3em;
        top: 50%;
        margin-top: -1.3em;
        background: #ffdcf7;
        height: 2em;
        width: 2em;
        border: .3em solid #fff;
        text-align: center;
        font-weight: bold;
        border-radius: 2em;
        transition: all .3s ease-out;
      }
          