
.slider-top-section{
    height : calc(100vh - 86px);
    background-color : rgba(36, 36, 36, 0.555);
    position: relative;
    overflow: hidden;
    top: 86px;
}

.slider-img-container{
    width: 100%; 
    height: 100%;
    position: relative;
}

.slider-img{
    width: 100%;
    height: 100%;
    position : absolute;
    object-fit: cover;
    filter: brightness(60%);
}

.slider-img-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, rgba(0,0,0,0.95), transparent, rgba(0,0,0,0.99));
}



.slider-text-container{
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translateX(-50%);
    /* left: 50%; */
    /* transform: translateX(-50%); */
    color: #f1f1f1;
    color: #e1e1e1;
    width: 86%;    
    margin: auto;
}

.slider-text-container > h2 {
    font-size: 2.8rem;
    color: white;
    margin-bottom: 2rem;
}

.slider-text-heading{
    font-family: sans-serif;
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 2rem;
    margin: 1rem 0;
    width: 100%;
    padding-bottom: 0.8rem;
    margin: 0;
    margin-bottom: 0.6rem;
    padding-left: 1.8rem;
    position: relative;
}

.slider-text-heading::before{
    content: '';
    position: absolute;
    width: 8px;
    height: 8px;
    background-color: rgba(255, 255, 255, 0.589);
    border-radius: 50%;
    left: 0;
    top: 30%;
}

.slider-text-description{
    font-size: 1.1rem;
}

.db-slide-marker-wrapper{
    bottom: 6%;
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
    background: rgba(0, 0, 0, 0.2);
    min-width: 200px;
    min-height: 16px;
    display: flex;
    justify-content: center;
    column-gap: 0.6rem;
    /* margin: auto; */
    align-items: center;
    border-radius: 30px;
    /* box-shadow: 0px 0px 4px 0.6px rgba(255, 255, 255, 0.829); */
}

.db-slide-marker-wrapper > * {
    height: 12px;
    width: 12px;
    border-radius: 50%;
    /* background-color: black; */
    border: 1px solid rgb(255, 255, 255);
}

.db-slide-active-marker{
    background-color: rgba(255, 255, 255, 0.836);
    border: 1px solid rgb(255, 255, 255);
}

.db-slider-btns-wrapper{
    display: flex;
    position: absolute;
    width: max-content;
    height: fit-content;
    min-width: 146%; 
    justify-content: space-between;
    /* top: -160%; */
    border: none;
}

.db-slider-btns-wrapper > .db-slider-btn{

    padding: 8px; 
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.137);
    width: 36px;
    height: 36px;
    border: 1px solid rgba(255, 255, 255, 0.336);
    transition: all 0.3s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover{
        background-color: rgba(255, 255, 255, 0.932);
        color: black;
    }
}

.db-slide-controls{
    width: 80%;
    height: 80%;
}

@media screen and (max-width : 1000px) {

    .slider-text-container{
        top: 10%;
    }

    .slider-text-heading{
        font-size: 1.2rem;
        padding: 0rem 0.6rem 0rem 0.6rem;
        margin: 0;
        margin-bottom: 1.8rem;
    }
    .slider-text-container > h2{
        font-size: 2.4rem;
        margin-bottom: 2rem;
    }

    .slider-text-heading::before{
        display: none;
    }
}

@media screen and (max-width : 400px) {
    .slider-text-description{
        display: none;
        overflow: hidden;
    }

    .slider-text-container{
        width: 100%;
        top: 10%;
    }

    .slider-text-container > h2 {
        font-size: 1.5rem;
        padding: 0rem 0.6rem 0rem 1rem;
    }

    .slider-text-heading{
        font-size: 1rem;
        padding: 0rem 0.6rem 0rem 1rem;
    }

}