.ira-ss-container{
    display: flex;
    /* background-color: rgb(17, 17, 66); */
}

.ira-ss-container > * {
    flex: 1;
}

.ira-ss-container  li{
    margin: 0 0 0 1.2rem;
    width: 80%;
    margin-bottom: 1rem;
    font-size: 1.06rem;
    color: #5e5e5e;
    font-family: sans-serif;
}

.ira-ss-text-head{
    font-size: 1.5rem;
    padding-bottom: 2rem;
    font-weight: 500;
    font-family: sans-serif;
    color: #161639;
    line-height: 2rem;
}

.ira-ss-img-wrapper{
    width: 100%;
    height: 100%;
    display: flex;
    /* justify-content: center; */
    align-items: center;
}

.ira-ss-img{
    /* border-radius: 20px; */
    border-radius: 50%;
    width: 94%;
    height: 90%;
    /* box-shadow: 0px 0px 3px 2px #adadad; */
}

@media screen and (max-width : 820px) {
    .ira-ss-img-wrapper{
        display: none;
    }
}