@import url('https://fonts.googleapis.com/css2?family=Archivo:ital,wght@0,100..900;1,100..900&family=Bakbak+One&family=Barlow+Condensed:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Bebas+Neue&family=Fjalla+One&family=Goldman:wght@400;700&family=Koulen&family=Monomaniac+One&family=Rubik+Mono+One&family=Signika+Negative:wght@300..700&family=Source+Code+Pro:ital,wght@0,200..900;1,200..900&family=Space+Grotesk:wght@300..700&display=swap');


.dc-bc-container{
    min-height: 580px;
    background-color: rgb(246, 245, 253);
}

.dc-bc-head{
    color : #333;
    padding: 3rem 0 2rem;
    font-size: 2rem;
    font-weight: 600;
    font-family: 'Arial', sans-serif;
    text-align: center;
}

.dc-bc-cards-wrapper{
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    gap : 1rem;
    padding-bottom: 1rem;
}


.dc-bc-basic-card {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    width: 300px;
    max-height: 360px;
    transition: all 0.3s ease;
    display: flex;
    flex-direction: column;
    cursor : pointer;
  }
  
  .dc-bc-basic-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .dc-bc-card-image {
    width: 100%;
    height: 200px;
    overflow: hidden;
  }
  
  .dc-bc-card-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;
  }
  
  .dc-bc-card-image:hover .dc-bc-card-img {
    transform: scale(1.1);
  }
  
  .dc-bc-card-content {
    padding: 1rem 2rem 0rem 2rem;
    flex-grow: 1;
  }
  
  .dc-bc-card-title {
    font-size: 1.5rem;
    font-weight: 400;
    color: #1a1a1a;
    font-family: 'Roboto', sans-serif;
  }
  
  .dc-bc-card-description {
    font-size: 1rem;
    color: #4b4b4b;
    line-height: 1.5;
  }
  
  .dc-bc-card-footer {
    background-color: #f8f8f8;
    padding: 10px 15px;
    text-align: center;
    border-top: 1px solid #e0e0e0;
  }
  
  .dc-bc-card-footer-text {
    font-size: 0.875rem;
    color: #007bff;
    cursor: pointer;
    transition: color 0.3s ease;
  }
  
  .dc-bc-card-footer-text:hover {
    color: #0056b3;
  }
  