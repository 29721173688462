.contactuspage-top{
    min-height: 514px;
    position: relative;
    overflow: hidden; 
    /* background-image: url('../../Images/contactsbg2.jpg'); */
    background-image: linear-gradient(to right, rgb(248, 248, 248) , rgb(243, 243, 243));
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
}


 

.contactuspage-data-container{
    position: relative;
    color: white;
    z-index: 1;
    text-align: center;
}



.contactuspage-head{
    backdrop-filter: blur(3px);
    padding: 1rem;
    font-family: system-ui;
    font-size: 2.4rem;
    font-weight: 500;
    margin-top: 1rem;
    margin-bottom: 2rem;
    color: rgb(23, 23, 95);
}

.contactuspage-form-one{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 40%;
    min-width: 340px;
    padding: 2rem 1rem;
    backdrop-filter: blur(6px);
    min-height: 300px;
    border-radius: 16px;
    border: 2px solid rgba(255, 255, 255, 1.658);
    background: #66c7d1;
    margin: auto;
    box-shadow: 0px 0px 8px 1px rgba(255, 255, 255, 0.534);
    margin-bottom: 2rem;
}

.contactuspage-form-one > input{
    background: none;
    border-radius: 20px;
    color: white;
    border: 2px solid rgb(255 255 255 );
    padding: 0.6rem 1.2rem;
    width: 64%;
    margin-bottom: 4%;
    &::placeholder{
        color: white;
    }
    &:focus{
        outline: none;
    }
    &:first-child{
        margin-top: 1.6rem;
    }
}


.contactuspage-form-one > button{
    background: none;
    border-radius: 30px;
    color: black;
    border: 1px solid rgb(255 255 255 );
    padding: 0.6rem 1.2rem;
    width: 24%;
    margin-top: 4%;
    transition: all 0.2s ease-in;
    background-color: white;
    &:hover{
        background-color: white;
        color: black;
        border-radius: 30px;
        scale: 1.1;
    }
}


@media screen and (max-width : 520px) {
    .contactuspage-form-one{
        width: 96%;
        padding: 0;
    }

    .contactuspage-form-one > input{
        width: 80%;
    }
}