
.main-intro-description {
    font-family: "Roboto" , sans-serif;
    font-size: 1.2rem;
}


.color-text{
    text-shadow: 4px -2px 0px white;
    background-image: linear-gradient(to right, blue, red);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
}

@media screen and (min-width: 960px) {
    .main-intro-real-top{
        background-image: url('../../../Images/introbg2.jpg');
        background-repeat: no-repeat;
        background-size: cover; 
        min-height: calc(100vh - 70px);
        width: 100vw;
    }
    .main-intro-top-section{
        padding: 4rem 1.6rem 2rem 8rem;
        /* background-image: linear-gradient( 136deg, rgb(243, 231, 231), rgb(255, 201, 241)); */
        /* rgb(255, 179, 223); */
        /* margin-bottom: 2rem; */
        /* background-image: url('../../../Images/world.jpg');
        background-repeat: no-repeat;
        background-size: cover;   */
        backdrop-filter: blur(4px);
        color: white;
    }

    .main-intro-wrapper{
        display: grid;
        /* grid-template-columns: 1fr 1fr; */
        width: 60%;
        /* text-align: start; */
        grid-auto-columns: 1fr;
        grid-auto-flow: column;
        grid-template-rows: auto;
        grid-column-gap: 2rem;
        grid-row-gap: 2rem;
        align-items: center;
        margin-bottom: 2rem;
        /* margin: auto; */
    }


    .main-intro-details{
        display: flex;
        flex-direction: column;
        text-align: left;
        column-gap: 2rem;
        row-gap: 2rem;
    }


    .main-intro-details > h1 {
        /* color: black; */
        font-size: 3rem;
        font-weight: 700;
        margin: 0;
        font-family: 'roboto' sans-serif; 
    }

    .main-intro-description{
        font-family: 'roboto' sans-serif;
    }

    .main-intro-details > .main-sub-heading{
        /* background: linear-gradient(135deg, #ce4be8ce 0%, #207ce5c5 100%); */
        background: linear-gradient(135deg, #fffeffce 0%, #ffffff 100%);
        font-family: system-ui;
        font-size: xx-large;
        font-weight: 500;
        background-clip: text;
        color: transparent;
    }

    .main-intro-alt-titles-wrapper{
        height: 4.5rem;
        /* background-color: aquamarine; */
        position: relative;
        overflow: hidden;
    }


    .main-intro-alt-titles-container{
        display: flex;
        flex-direction: column;
        position: absolute;
        /* transform: translate3d(0px, -80%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); */
        transform-style: preserve-3d;
        animation-name: animate_title;
        animation-duration: 6s;
        animation-timing-function: linear;
        animation-delay: 0s;
        animation-fill-mode: none;
        animation-iteration-count: infinite;
        animation-direction: normal;
    }

    .main-intro-each-title{
        /* height: 4.5rem; */
        /* margin-bottom: 0; */
        font-size: 3.2rem;
        font-weight: 600;
        /* line-height: 1.2; */
        position: relative;
    }

    .main-intro-button-wrapper{
        display: flex;
        flex-direction: column;
        padding: 1rem 1rem;
        text-align: left; 
        overflow: hidden;
        column-gap: 1rem;
        row-gap: 1rem;
        align-items: start;
    }

    .main-intro-img-container{
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .main-intro-img{
        width: 80%;
        border-radius: 8px;
    }

}






@media screen and (max-width: 960px) {
    
    .main-intro-real-top{
        background-image: url('../../../Images/introbg2.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        color: white;
    }
    .main-intro-top-section{
        padding: 3rem 1.6rem 2rem 1.6rem;
        /* background-image: linear-gradient(136deg,rgb(255, 201, 241), rgb(243, 231, 231), rgb(255, 201, 241)); */
        /* margin-bottom: 2rem; */
        backdrop-filter: blur(4px);
    }

    .main-intro-wrapper{
        display: flex;
        flex-direction: column;
        row-gap: 2rem;
        margin-bottom: 2rem;   
    }



    .main-intro-alt-titles-wrapper{
        height: 4.5rem;
        /* background-color: aquamarine; */
        position: relative;
        overflow: hidden;
    }


    .main-intro-alt-titles-container{
        display: flex;
        flex-direction: column;
        position: absolute;
        /* transform: translate3d(0px, -80%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); */
        transform-style: preserve-3d;
        animation-name: animate_title;
        animation-duration: 6s;
        animation-timing-function: linear;
        animation-delay: 0s;
        animation-fill-mode: none;
        animation-iteration-count: infinite;
        animation-direction: normal;
    }

    .main-intro-each-title{
        /* height: 4.5rem; */
        /* margin-bottom: 0; */
        font-size: 3.2rem;
        font-weight: 600;
        /* line-height: 1.2; */
        position: relative;
    }

    .main-intro-details{
        display: flex;
        flex-direction: column;
        text-align: left;
        column-gap: 2rem;
        row-gap: 2rem;
    }

    .main-intro-details > h1 {
        /* color: black; */
        font-size: 3rem;
        font-weight: 700;
        margin: 0; 
    }

    .main-intro-button-wrapper{
        display: flex;
        flex-direction: column;
        padding: 1rem 1rem;
        text-align: left; 
        overflow: hidden;
        column-gap: 1rem;
        row-gap: 1rem;
        align-items: center;
    }

    .main-intro-img-container{
        width: 100%;
    }

    .main-intro-img{
        width: 80%;
        border-radius: 14px;
    }

    .main-intro-details > .main-sub-heading{
        background: linear-gradient(135deg, #ce4be8ce 0%, #207ce5c5 100%);
        font-family: system-ui;
        font-size: xx-large;
        font-weight: 500;
        background-clip: text;
        color: transparent;
    }

}


@media screen and (max-width: 290px){
    .main-intro-real-top{
        background-image: url('../../../Images/introbg2.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        color: white;
    }
    .main-intro-top-section{
        padding: 3rem 1.6rem 2rem 1.6rem;
        /* background-image: linear-gradient(136deg,rgb(255, 201, 241), rgb(243, 231, 231), rgb(255, 201, 241)); */
        /* margin-bottom: 2rem; */
        backdrop-filter: blur(4px);
    }

    .main-intro-wrapper{
        display: flex;
        flex-direction: column;
        row-gap: 2rem;
        margin-bottom: 2rem;   
    }



    .main-intro-alt-titles-wrapper{
        height: 4.5rem;
        /* background-color: aquamarine; */
        position: relative;
        overflow: hidden;
    }


    .main-intro-alt-titles-container{
        display: flex;
        flex-direction: column;
        position: absolute;
        /* transform: translate3d(0px, -80%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); */
        transform-style: preserve-3d;
        animation-name: animate_title;
        animation-duration: 6s;
        animation-timing-function: linear;
        animation-delay: 0s;
        animation-fill-mode: none;
        animation-iteration-count: infinite;
        animation-direction: normal;
    }

    .main-intro-each-title{
        /* height: 4.5rem; */
        /* margin-bottom: 0; */
        font-size: 2.2rem;
        font-weight: 600;
        /* line-height: 1.2; */
        position: relative;
    }

    .main-intro-details{
        display: flex;
        flex-direction: column;
        text-align: left;
        column-gap: 2rem;
        row-gap: 2rem;
    }

    .main-intro-details > h1 {
        /* color: black; */
        font-size: 2rem;
        font-weight: 700;
        margin: 0; 
    }

    .main-intro-button-wrapper{
        display: flex;
        flex-direction: column;
        padding: 1rem 1rem;
        text-align: left; 
        overflow: hidden;
        column-gap: 1rem;
        row-gap: 1rem;
        align-items: center;
    }

    .main-intro-img-container{
        width: 100%;
    }

    .main-intro-img{
        width: 80%;
        border-radius: 14px;
    }



}


@keyframes animate_title{
    0% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(-100%);
    }
}