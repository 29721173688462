
.db-news-updates-sect{
    position: relative;
    top: 86px;
    min-height: 600px;
    background-color: rgb(255, 255, 255);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1% 1% 2% 1%;
}

.db-news-updates-head{
    font-size: 2rem;
    font-weight: bold;
    font-family: sans-serif;
    padding: 2rem 0;
    text-align: center;
    color: black;
}

.db-news-img{
    width: 80vw;
    height: auto;
}