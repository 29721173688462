@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Tiny5&display=swap');

.roboto-medium {
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-style: normal;
  }

  .nav-top-section  a {
    font-family: "Roboto" sans-serif;
    font-weight: 500;
  }



.scrolled{
    background-color: white;
    transition: all 0.5s ease-in-out;
    box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.197);
}


.nav-top-section{
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 86px;
    position: fixed;
    z-index: 1000;
    width: 100%;
    transition: all 0.5s ease-in-out;
}

.hb-icon{
    width: 34px;
    height: 34px;
}



@media screen and (min-width : 1086px) {

    .dc-nav-options{
        display: flex;
        flex-direction: row;
        column-gap: 1rem;
        position: relative;
        align-items: center;
    } 

    .dc-nav-each-option{
        position: relative;
        &:hover{
            background-color: #c6d9db;
            border-radius: 6px 6px 0 0;
        }
    }

    .dc-nav-a{
        padding: 10px 14px;
        display: inline-block;
        color : rgb(230, 9, 9);
        height: inherit;
    }

    .dc-nav-each-option:hover .dc-dd-container{
        visibility: visible;
        opacity: 1;
    }

    .dc-dd-container{
        visibility: hidden;
        opacity: 0;
        min-height: 200px;
        min-width: 180px;
        position: absolute;
        border-radius: 0 8px 8px 8px;
        background-color: #c6d9db;
        box-shadow: 2px 3px 2px 1px #9f9f9f;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        justify-content: start;
        /* left: -50%; */
        transition: all 0.5s ease-in-out;
        padding-top: 1rem;
    }

    .dc-dd-items{
        border: 1px solid #21284b;
        padding: 0.6rem 2rem;
        border-radius: 30px;
        margin: 0 1rem;
        background: #1c1c47;
        color: white;
        font-family: sans-serif;
        cursor: pointer;
        &:hover{
            transform: scale(1.02);
        }
    }

    .hamburger-menu-dd-top, .hamburger-menu{
        display: none;
    }
}






@media screen and (max-width : 1086px) {

    .dc-nav-options{
        display: none;
    }
    
    .nav-top-section{
        justify-content: space-between;
        background-color: white;
    }

    .nav-top-section > .hamburger-menu {
        margin-right: 1rem;
    }

    .nav-top-section > .name-logo-container{
        margin-left: 1rem;
    }

    .hamburger-menu{
        display: block;
        padding: 4px;
        cursor: pointer;
    }

    .nav-options, .right-section{
        display: none;
    }

    .hamburger-menu-dd-top{
        background-color: rgba(255, 255, 255, 0.5);
        top: 55px;
        left: 0;
        margin-top: 31px;
        min-height: 60vh;
        height: 100vh;
        position: absolute;
        width: 100%;
        z-index: 10;
        padding: 1rem 2rem;
        backdrop-filter: blur(4px);
    }

    .show-hb-dd{
        display: block;
    }

    .hide-hb-dd{
        display: none;
    }

    .hb-menu-dd-item-name{
        padding: 1.4rem 1rem;
        color: white;
        font-family: sans-serif;
        font-size: 1.4rem;
        font-weight: 400;
        display: flex;
        justify-content: space-between;
        text-decoration: none;
        cursor: pointer;
    }

    .hb-menu-dd-items:hover{
         background-color: rgba(129, 129, 129, 0.8);
    }

    .hb-menu-dd-items{
        width: 94%;
        border-bottom: 1px solid rgba(255, 255, 255, 0.5);
        background-color: rgba(0, 0, 0, 0.5);
        border-radius: 10px 10px 10px 10px;
        padding-bottom: 0.8rem;
        transition: all 0.2s ease-in-out;
    }

    .arr-down-img{
        width: 34px;
        height: 34px;
    }

    .hb-menu-dd-sub-items{
        color: white;
        font-family: sans-serif;
        font-size: 1.2rem;
        font-weight: 100;
        display: flex;
        flex-direction: column;
        padding-left: 1rem;
        padding-right: 1rem;
    }
    
    .show-sub-items{
        display: flex;
    }

    .hide-sub-items{
        display: none;
    }

    .dd-sub-item{
        color: white;
        background-color: none;
        text-decoration: none;
        padding: 1rem 1rem;
        transition: all 0.5s ease-in-out;
        border-radius: 2rem;
        &:hover{
            transition: all 0.3s ease-in-out;
            background-color: rgba(255, 255, 255, 0.795);
            color: black;
            border-radius: 2rem;
        }
    }
}



@media screen and (max-width : 400px) {
    
    .hamburger-menu-dd-top{
        padding: 1rem 0;
    }

    .hb-menu-dd-items{
        width: 100%;
    }
    
    .nav-top-section{
        background-color: white;
    }
}