
.db-about-container{
    position: relative;
    top: 86px;
    min-height: 100vh;
}



.db-about-intro-container{
    background: url('../../Images/AboutUs/2151780762.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 54vh;
    position: relative;
    z-index: 4;
    margin-bottom: 2rem;
    padding-top: 6rem;
}

.db-about-intro-container::before{
    content: '';
    position: absolute;
    inset: 0;
    background: rgba(0, 0, 0, 0.747);
    z-index: 5;
}






.db-about-each-section{
    display: flex;
    justify-content: space-between;
}

.db-about-each-section > .db-about-each-section-texts{
    width: 60%;
    padding: 0 2rem;
    padding-top: 3rem;
}

.db-about-each-section > .db-about-each-section-img-container{
    width: 30%;
    text-align: center; 
    align-content: center;
}

.db-about-each-section-img{
    width: 100%;
}

.db-about-each-section-head{
    text-align: center;
    padding: 0rem 0 1rem ;
    font-family: sans-serif;
    font-size: 1.7rem;
    font-weight: 600;
    color: #14154b;
}

.db-about-each-section-description{
    color: rgb(136, 134, 134);
    font-size: large;
    font-family: system-ui;
    font-weight: 400;
    line-height: 1.6rem;
    letter-spacing: 0.02rem;
}

.db-about-core-container{
    text-align: center;
    min-height: 400px;
    background-color: rgb(253, 253, 253);
    margin-bottom: 2rem;
}

.db-about-core-values-wrapper{
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 0 2rem;
}

.db-about-each-core-value{
    min-height: 240px;
    min-width: 100px;
    max-width: 240px;
    box-shadow: 0 1px 7px 1px #ededed;
    padding: 1rem 2%;
}

.db-about-core-value-head{
    text-align: center;
    padding: 2rem 2rem;
    font-family: sans-serif;
    font-size: xx-large;
    font-weight: 600;
    color: #14154b;

}

.db-about-each-core-value-head{
    /* background: linear-gradient(to right, #5e60e7, #fa5eff); */
    background: linear-gradient(to right, #000000, #000000);
    color: transparent;
    background-clip: text;
    font-size: large;
    font-weight: 500;
    margin-bottom: 1rem;
}

.db-about-each-core-value-desc{
    font-family: sans-serif;
    color: grey;
    text-align: left;
    line-height: 1.4rem;
    letter-spacing: 0.02rem;
}

.db-about-futuregoals-container{
    min-height: 400px;
    background-color: rgb(255, 255, 255);
    padding-bottom: 6rem;
}

.db-about-futuregoals{
    display: flex;
    gap: 2rem;
    padding: 0 6%;
    flex-wrap: wrap;
    justify-content: center;
}

.db-about-each-futuregoals{
    padding: 0 2%;
    min-width: 40%;
    max-width: 400px;
    position: relative;
    z-index: 8;
}

.db-about-each-futuregoals-head{
    font-weight: 500;
    font-size: 1.2rem;
    font-family: system-ui;
    margin-bottom: 0.6rem;
    color: #1c1d63;
}

.db-about-each-futuregoals-desc{
    font-weight: 400;
    font-size: 1rem;
    font-family: system-ui;
    color: #6f6f6f;
}

.db-about-each-futuregoals-logo{
    width: 60%;
    height: 60%;
    /* padding: 1px; */
}

.db-about-each-futuregoals-logo-cont{
    width: 42px;
    height: 42px;
    text-align: center;
    align-content: center;
    border-radius: 50%;
    background-color: #f7f5f5;;
    position: absolute;
    left: -8%;
    top: 0;
}

#ourmissionimg{
    transform: scale(1.4) translateX(20px);
}

@media screen and (min-width : 480px){
    .db-about-intro-texts{
        position: relative;
        color: white;
        z-index: 6;
        width: 80%;
        margin: auto;
        font-family: sans-serif;
        font-size: 1.2rem;
        text-align: start;
    }

    .db-about-sections{
        display: flex;
        flex-direction: column;
        width: 76%;
        margin: auto;
        gap: 0rem;
        margin-bottom: 3rem;
    }

}

@media screen and (max-width : 940px) {
    .db-about-sections{
        gap: 3rem;
    }
}

@media screen and (max-width : 480px){
    
    .db-about-each-section-head{
        text-align: start;
    }

    .db-about-intro-texts{
        position: relative;
        color: white;
        z-index: 6;
        width: 90%;
        margin: auto;
        font-family: sans-serif;
        font-size: medium;
        text-align: start;
    }
    
    .db-about-each-core-value{
        max-width: 300px;
    }

    .db-about-each-futuregoals-logo-cont{
        position:unset;
        background-color: white;
    }

    .db-about-sections{
        display: flex;
        flex-direction: column;
        width: 100%;
        margin: auto;
        gap: 0rem;
        margin-bottom: 3rem;
        row-gap: 2rem;
        column-gap: 2rem;
    }
}


@media screen and (max-width : 920px){

    .db-about-intro-container{
        padding-top: 2rem;
        letter-spacing: 0.08rem;
        line-height: 1.3rem;
    }

    .db-about-each-section-img-container{
        display: none;
    }

    .db-about-each-section > .db-about-each-section-texts{
        width: 100%;
        padding: 0 2rem;
    }


}