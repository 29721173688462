.about-company > img {
    border-radius: 10px;
    height: 100%;
    width: 80%;
}

.footer-top{
    position: relative;
    min-height: 300px;
    background-color: rgb(19, 19, 19);
    color: white;
    top: 86px;
    display: grid;
    grid-template-rows: auto;
    grid-auto-flow: row;
    grid-auto-rows: 1fr;
    grid-auto-columns: 1fr;
    padding: 2rem 2.2rem;
    grid-column-gap: 2.4rem;
}

.footer-top > * {
    display: flex;
    flex-direction: column;
    padding-top: 2rem;
}

.social-container{
    display: flex;
    align-items: center;
    column-gap: 1rem;
    padding: 0;
    flex-wrap: wrap;
}

.social-icon{
    width: 32px;
    height: 32px;
    background-color: white;
    padding: 6px;
    border-radius: 4px;
}

.footer-col-head{
    padding: 0.4rem 0 ;
    opacity: .85;
    margin: 0 0 10px;
    font-size: 1.04rem;
    font-weight: 700;
    text-transform: uppercase;
    text-decoration: underline white;
    cursor: pointer;
}

.footer-top  a{
    color: #9c9c9c;
    padding: 0.4rem 0;
    text-decoration: none;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.6;
    transform: translateX(0);
    transition: all 0.2s ease-in;
    &:hover{
        color: white;
        transform: translateX(2px);
    }
}

.about-company{
    /* background: linear-gradient(to right, white, rgb(228, 228, 228)); */
    /* padding: 0.1rem 1rem 0 0; */
    width: 54%;
    margin-bottom: 1.2rem;
}

.company-add > *, .email-texts > *{
    padding: 0.4rem;
}


.db-f-head-pad{
    padding-top: 4.8rem;
}

@media screen and (min-width : 920px) {
    
    .footer-top{
        grid-template-columns: 1fr 1fr 1fr;
    }
}


@media screen and (max-width : 920px) {
    
    .footer-top{
        grid-template-columns: 1fr 1fr ;
    }
}


@media screen and (max-width : 540px) {
    
    .footer-top{
        grid-template-columns: 1fr;
        padding: 2rem 1.8rem;
    }

    .db-f-head-pad{
        padding-top: 0rem;
    }
}