.sf-footer-real-top{
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    min-height: 400px;
    color: white;
    padding-top: 2.4rem;
    top: 70px;
}

.sf-footer-top{
    display: grid;
    grid-template-rows: auto;
    grid-auto-flow: row;
    grid-auto-rows: 1fr;
    grid-auto-columns: 1fr;
    padding: 1rem 4%;
    grid-column-gap: 2.4rem;
    /* background-color: #f3f3f3; */
    background-color: #f8f8f8;
    margin-inline: 4.5%;
    border-radius: 4px 4px 24px 24px;
    grid-row-gap: 1rem;
}

.sf-footer-top > * {
    display: flex;
    flex-direction: column;
    margin-bottom : 1.6rem;
    /* align-items: center; */
    /* justify-content: center; */
}

.sf-social-container{
    display: flex;
    align-items: center;
    column-gap: 12px;
    padding: 0;
    flex-wrap: wrap;
}

.sf-social-icon{
    width: 32px;
    height: 32px;
    background-color: white;
    padding: 6px;
    border-radius: 4px;
}

.sf-footer-col-head{
    color: #091e42;
    margin: 0 0 10px;
    font-weight: 700;
    text-transform: uppercase;
    cursor: pointer;
    font-family: sans-serif;
    text-transform: uppercase;
}

.sf-footer-top  a{
    color: #101214;    
    padding: 0.4rem 0;
    text-decoration: none;
    font-family: system-ui;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.6;
    transform: translateX(0);
    transition: all 0.2s ease-in;
    &:hover{
        text-decoration: underline;
        transform: translateX(2px);
    }
}

.sf-about-company{
    margin-bottom: 1.2rem;
    transform: translateX(-1rem);
}

.sf-company-details, .sf-about-products, .sf-social-media{
    text-align: left;
}

.sf-about-company > img{
    width: 48%;
}

.sf-about-products > a, .sf-company-add > *, .sf-email-texts > *{
    color: #101214;
    font-size: initial;
    padding: 8px 0px;
}

.sf-copyright{
    text-align: center;
    padding: 2rem 0;
    color: black;
}

.sf-f-head-pad{
    padding-top: 4rem;
}

@media screen and (min-width : 920px) {
    
    .sf-footer-top{
        grid-template-columns: 1fr 1fr 1fr;
    }
}


@media screen and (max-width : 920px) {
    
    .sf-footer-top{
        grid-template-columns: 1fr 1fr ;
    }
}


@media screen and (max-width : 540px) {
    
    .sf-footer-top{
        grid-template-columns: 1fr;
        /* padding: 2rem 1.8rem; */
    }

    .sf-f-head-pad{
        padding-top: 0rem;
    }
}