@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

.main-about-container{
    /* min-height: 600px; */
    /* background-color: #748494; */
    background-color: #fff;
    padding-top: 90px;
    padding-bottom: 100px;
}

.main-about-wrapper{
    max-width: 1200px;
    margin: auto;
    display: flex;
    min-height: 400px; 
    background-color: #fff;
    column-gap: 2rem;
}

.main-about-left{
    width: 50%;
    min-height: 200px;
    position: relative;
    /* background-color:#748494; */
}

.main-about-right{
    width: 40%;
}

.main-about-wrapper > .main-about-left, .main-about-right{
    padding: 0 1% 0 1%;
    text-align: start;
}

.main-about-content-wrapper{
    max-width: 440px;
}

.main-about-heading{
    color: #030f27;
    font-size: 30px;
    line-height: 42px;
    font-family: "Roboto", math;
    margin: 0;
}

.main-about-head-section{
    margin-bottom: 1.8rem;
}

.main-about-desc-section{
    color: #748494;
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.74;
    overflow-x: hidden;
    position: relative;
    letter-spacing: 0.5px;
}


/* .main-about-left{
    position: relative;
} */

.main-about-img-section > * {
    position: absolute;
    max-width: 60%;
    border-radius: 18px;
    /* box-shadow: 0px 0px 0px 10px white; */
    box-shadow: 0px 0px 8px 3px white;
    /* border: 1px solid white; */
}


.about-img1{
    top: 20%;
    left: 4%;
}

.about-img2{
    top: 0%;
    left: 36%;
}



@media screen and (max-width : 500px) {
    .main-about-wrapper{
        flex-direction: column-reverse;
        gap: 2rem;
        align-items: center;
    }

    .main-about-left{
        width: 100%;
    }

    .main-about-left > * {
        position: relative;
        width: 100%;
        height: 100%;
    }

    .main-about-img-section > *{
        max-width: 100%;
        /* max-height: fit-content; */
    }

    .about-img1{
        inset: 0;
    }
    .about-img2{
        inset: 0;
    }

    .main-about-right{
        width: 96%;
    }

    .main-about-heading{
        text-align: center;
    }
}