.sf-aboutproduct-top-section{
    background-image: linear-gradient(to right top, #ac5398, #9ea2f3);
}

.sf-aboutproduct-container{
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    text-align: left;
}

@media screen and (min-width : 620px) {
    .sf-aboutproduct-container{
        padding: 5rem 10rem 3rem;
    }
    
    .sf-aboutproduct-container > * {
        /* width: 50%; */
    }
    
    .sf-aboutproduct-heading{
        margin: 0 0 1.8rem 0;
        font-size: 2rem;
    }
    
    .sf-aboutproduct-description{
        margin: 0 0 1.8rem 0;
        font-size: 1.12rem;
        line-height: 1.8;
        color: rgb(248, 248, 248);
    }
    
    .sf-aboutproduct-btn{
        margin: 0 0 1.8rem 0;
    }
}



@media screen and (max-width : 680px) {
    .sf-aboutproduct-container{
        /* background: radial-gradient(79.69% 102.24% at 100% 100.11%, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)), radial-gradient(89.7% 115.09% at 3.43% 2.75%, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0)), #764ABC; */
        color: white;
        padding: 2rem 1rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: start;
        text-align: left;
    }
    
    .sf-aboutproduct-container > * {
        /* width: 50%; */
    }
    
    .sf-aboutproduct-heading{
        margin: 0 0 1.8rem 0;
        font-size: 1.8rem;
    }
    
    .sf-aboutproduct-description{
        margin: 0 0 1.8rem 0;
        font-family: sans-serif;
        font-size: 1rem;
        line-height: 1.5;
    }
    
    .sf-aboutproduct-btn{
        margin: 0 0 1.8rem 0;
    }
}



@media screen and (max-width : 200px) {
    .sf-aboutproduct-container{
        padding: 2rem 0;
    }
}