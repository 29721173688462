.ira-card-card-container{
    padding-top: 4rem;
    padding-bottom: 6rem;
    background-color: #f9feff;
}

.ira-card-card-wrapper{
    max-width: 1200px;
    margin: auto;
    display: flex;
    justify-content: center;
    gap: 2rem;
    flex-wrap: wrap;
}

.ira-card-card-wrapper > *{
    /* flex: 1 1 calc(33.333% - 16px); */
    flex-basis: calc(30% - 16px);
    min-width: 200px;
}

.ira-card-each-card{
    min-height: 320px;
    max-width: 400px;
    min-width: 280px;
    background-color: #fff;
    border: 1px solid #e7e7e7;
    padding: 2rem 2rem;
    border-radius: 5px;
    &:hover{
        box-shadow: 0 0 40px rgba(50, 50, 50, .1);
        transform: translateY(-5px);
        z-index: 1;
    }
}

.ira-card-img{
    width: 24%;
}

.ira-card-des-container{
    font-family: 'Roboto';
}

.ira-card-head{
    font-size: 1.1rem;
    color: #122a5a;
    font-weight: 500;
    padding: 1rem 0;
}

.ira-card-text{
    color: #748494;
    font-size: 1rem;
    font-family: system-ui;
    font-weight: 400;
    letter-spacing: 0.4px;
}