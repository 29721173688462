/* LandingPage.css */
@import url('https://fonts.googleapis.com/css2?family=Archivo:ital,wght@0,100..900;1,100..900&family=Bakbak+One&family=Barlow+Condensed:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Bebas+Neue&family=Fjalla+One&family=Goldman:wght@400;700&family=Koulen&family=Monomaniac+One&family=Rubik+Mono+One&family=Signika+Negative:wght@300..700&family=Source+Code+Pro:ital,wght@0,200..900;1,200..900&family=Space+Grotesk:wght@300..700&display=swap');

/* LandingPage.css */
.dc-land-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 40px;
    background-color: #d7e0f33b;
    font-family: 'Arial', sans-serif;
    overflow: hidden;
  }
  
  .dc-land-content {
    flex: 1;
    /* max-width: 600px; */
    padding: 6rem 6rem 4rem 6rem; /* Space between text and image */
  }
  
  .dc-land-title {
    font-family: "Goldman", sans-serif;
    font-weight: 500;
    font-size: 5rem;
    color: #585858;
    margin-bottom: 10px;
  }
  
  .dc-land-description {
    font-size: 1.5rem;
    color: #666;
    margin-bottom: 30px;
  }
  
  .dc-land-text {
    font-size: 1.2rem;
    color: #919191;
    line-height: 1.6;
    margin-bottom: 30px;
  }
  
  .dc-land-image {
    flex: 1;
    max-width: 400px; /* Image size */
    position: relative;
  }
  
  .dc-land-img {
    width: 100%;
    height: auto;
    border-radius: 8px;
    position: absolute;
    left: -24%;
    top: -60px;
    width: 600px;
    height: 340px;
    transform: rotate(-47deg) scale(1.6);

  }



  
  /* Responsive Design for smaller screens */
  @media (max-width: 700px) {
    .dc-land-container {
      flex-direction: column;
      align-items: center;
      padding: 0;
    }
  
    .dc-land-content {
      text-align: center;
      padding: 6rem 2rem;
      max-width: 100%;
      margin-bottom: 30px; /* Add some spacing between text and image */
    }
  
    .dc-land-title {
      font-size: 2.5rem;
    }
  
    .dc-land-description {
      font-size: 1.2rem;
    }
  
  
    .dc-land-image {
      display: none;
      width: 100%;
      max-width: 400px;
    }
  }
  

  @media (max-width: 1050px) {
    .dc-land-image{
        display: none;
    }

    .dc-land-content{
      padding: 6rem 1rem 2rem;
    }
  }