.nav-top-section{
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 86px;
    /* background: linear-gradient(to right, rgb(255, 255, 255) , rgb(8, 21, 56)); */
    background-color: #f9f9f9;
    position: fixed;
    z-index: 1000;
    width: 100%;
    box-shadow: 0px 0px 9px 1px #00000094;
}

.name-logo-container > img{
    width: 100%;
    height: 100%;
    /* transform: scale(2); */
}

.name-logo-container{
    /* background-color: red; */
    height: 70%;
    width: 180px;
    overflow: hidden;
}

.hb-icon{
    width: 34px;
    height: 34px;
}

@media screen and (min-width : 1086px) {

    .hamburger-menu{
        display: none;
    }

    .nav-options{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        width: 50%;
    }

    .nav-options a{
        text-decoration: none;
        color: white;
    }

    .nav-options > .nav-each-option > a , .right-section > * {
        padding: 30px 1rem;
        font-family: sans-serif;
        font-size: 1.08rem;
        font-weight: 400;
        text-decoration: none;
        /* color: white; */
        color: black;
        cursor: pointer;
        transition: all 0.5s ease;
        &:hover{
            /* background: rgba(255, 255, 255, 0.863);
            color: black; */
            background: black;
            color: rgb(255, 255, 255);
        }
    }

    .nav-each-option:hover > a {
        /* background-color: rgb(158, 13, 13);
        color: black; */
        background: black;
        color: rgb(255, 255, 255);
    }

    .nav-each-option:hover > .nav-each-hov-section{
        visibility: visible;
        opacity: 1;
        display: flex;
        flex-direction: row;
        column-gap: 1rem;
    }


    .nav-each-hov-section{
        display: flex;
        background-color: rgba(0, 0, 0, .5);
        visibility: hidden;
        opacity: 0;
        left: 0;
        margin-top: 26px;
        min-height: 360px;
        position: absolute;
        width: 100%;
        z-index: 10;
        column-gap: 2rem;
        backdrop-filter: blur(4px);
        transition: all 0.4s ease-in-out;
    }

    .nav-each-hov-about-wrapper{
       display: flex;
       flex-direction: column;
       align-items: center;
       background-color: rgba(0, 0, 0, 0.801);
    }

    .nav-hov-about-desc{
        color: #ededed;
        padding: 6rem 10rem 3rem;
        line-height: 2rem;
        font-size: 1.18rem;
        width: 100%;
    }

    .nav-hov-about-link{
        padding: 0.6rem 1.8rem;
        border-radius: 30px;
        border: 2px solid white;
        color: #131313;
        transition: all 0.5s ease-in-out;
        &:hover{
            background-color: white;
            color: black;
        }
    }

    .nav-hov-sub-options{
        position: absolute;
        left: 100%;
        top: 0%;
        display: none;
        flex-direction: column;
        width: fit-content;
        min-width: 260px;
        overflow-y: scroll;
        scrollbar-width: none;
        height: 300px;
        opacity: 0;
        transition: all 0.3s ease, opacity 0.3s ease;
    }

    .nav-hov-sub-options > * {
        padding: 20px 1rem;
        margin-left: 2rem;
        border-bottom: 1px solid white;
    }

    .nav-hov-sub-options > *:hover{
        background-color: rgba(255, 255, 255, 0.2);
    }

    .nav-hov-options{
        display: flex;
        flex-direction: column;
        width: 20%;
    }

    .nav-hov-description{
        width: 40%;
        color: white;
        text-decoration: none;
        background-color: rgba(0, 0, 0, 0.822);
        line-height: 1.6rem;
        font-size: 1.1rem;
        padding: 4.8rem 5rem 2rem;
    }

    .nav-hov-options > * {
        border-bottom: 1px solid white;
    }

    .nav-hov-options > *:hover{
        background-color: rgba(255, 255, 255, 0.212);
    }

    .nav-hov-each-option:hover > .nav-hov-sub-options{
        display: flex;
        width: 20%;
        opacity: 1;
        transition: all 0.3s ease, opacity 0.3s ease;
    }

    .nav-hov-each-option{
        position: relative;
        padding: 20px 1rem;
        text-decoration: none;
        color: white;
        cursor: pointer;
    }

    .hamburger-menu-dd-top{
        display: none;
    }

}






@media screen and (max-width : 1086px) {
    .nav-top-section{
        justify-content: space-between;
        /* padding: 0 20px; */
    }

    .nav-top-section > .hamburger-menu {
        margin-right: 1rem;
        background-color: black;
    }

    .nav-top-section > .name-logo-container{
        margin-left: 1rem;
    }

    .hamburger-menu{
        display: block;
        padding: 4px;
        cursor: pointer;
    } 

    .nav-options, .right-section{
        display: none;
    }

    .hamburger-menu-dd-top{
        background-color: rgba(0, 0, 0, .5);
        /* display: none; */
        /* top: 55px; */
        top: 100%;
        left: 0;
        /* margin-top: 31px; */
        /* min-height: 300px; */
        /* min-height: 100vh; */
        height: 87vh;
        /* overflow-y: scroll; */
        /* scrollbar-width: thin; */
        position: absolute;
        width: 100%;
        z-index: 10;
        padding: 1rem 2rem;
        backdrop-filter: blur(4px);
        overflow-y: scroll;
    }

    .show-hb-dd{
        display: block;
    }

    .hide-hb-dd{
        display: none;
    }

    .hb-menu-dd-item-name{
        padding: 1.4rem 1rem;
        color: white;
        font-family: sans-serif;
        font-size: 1.4rem;
        font-weight: 400;
        display: flex;
        justify-content: space-between;
        cursor: pointer;
    }

    .hb-menu-dd-items:hover{
        background-color: rgba(255, 255, 255, 0.1);
    }

    .hb-menu-dd-items{
        width: 94%;
        border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    }

    .arr-down-img{
        width: 34px;
        height: 34px;
    }

    .hb-menu-dd-sub-items{
        color: white;
        font-family: sans-serif;
        font-size: 1.2rem;
        font-weight: 100;
        display: flex;
        flex-direction: column;
        padding-left: 1rem;
        padding-right: 1rem;
    }
    
    .show-sub-items{
        display: flex;
    }

    .hide-sub-items{
        display: none;
    }

    .dd-sub-item{
        color: white;
        text-decoration: none;
        padding: 1rem 1rem;
        &:hover{
            border-radius: 32px;
            background-color: rgba(255, 255, 255, 0.5);
        }
    }
}



@media screen and (max-width : 400px) {
    
    .hamburger-menu-dd-top{
        padding: 1rem 0;
    }

    .hb-menu-dd-items{
        width: 100%;
    }
}