.sf-banner2-top-section{
    background-image: linear-gradient(to right, white, rgb(250, 250, 250));
    min-height: 360px;
}

.sf-banner2-container{
    color: rgb(10, 24, 100);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    text-align: left;
}

@media screen and (min-width : 620px) {
    .sf-banner2-container{
        padding: 5rem 10rem 3rem;
    }
    
    .sf-banner2-container > * {
        /* width: 80%; */
        margin: auto;
    }
    
    .sf-banner2-heading{
        margin: 0 0 1.8rem 0;
        font-size: 2rem;
    }
    
    .sf-banner2-description{
        color: rgb(87, 87, 87);
        margin: 0 0 1.8rem 0;
        font-family: sans-serif;
        font-size: 1.09rem;
        line-height: 1.8;
    }
    
    .sf-banner2-btn{
        margin: 0 0 1.8rem 0;
    }
}



@media screen and (max-width : 680px) {
    .sf-banner2-container{
        padding: 2rem 1rem;
    }
    
    
    .sf-banner2-heading{
        margin: 0 0 1.8rem 0;
        font-size: 1.8rem;
    }
    
    .sf-banner2-description{
        margin: 0 0 1.8rem 0;
        font-family: sans-serif;
        font-size: 1rem;
        line-height: 1.5;
    }
    
    .sf-banner2-btn{
        margin: 0 0 1.8rem 0;
    }
}



@media screen and (max-width : 200px) {
    .sf-banner2-container{
        padding: 2rem 0;
    }
}