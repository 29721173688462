




.creative-card-wrapper{
    min-width: 80%;
    position: relative; 
    display: flex;
    justify-content: center;
    overflow: hidden;
    border-radius: 8px;
    transition: all 0.3s ease-in-out;
    box-shadow: 0 0 4px 2px rgb(216 216 216);

    &:hover{
        box-shadow: 0 0 4px 2px rgb(184, 184, 184);
        transform: translateY(-6px);
    }
}


.creative-card-wrapper::before{
    content: '';
    position: absolute;
    width: 100%;
    height: 140%;
    filter: blur(64px); 
    animation-duration: 4s;
    animation-delay: 0s;
    animation-timing-function:linear;
    animation-fill-mode: none;
    animation-iteration-count: infinite;
    animation-direction: normal; 
}


.creative-card-wrapper::after{
    content: '';
    position: absolute;
    background-color: rgb(255, 255, 255);
    inset: 3px;
    border-radius: inherit;
}



.creativecard-data-container{
    padding: 2rem 2rem 2rem 3rem;
    color: rgb(9, 9, 41);
    z-index: 1;
    width: 100%; 
    height: 100%;
}


.creativecard-data-description-section{
    margin-bottom: 2rem;
    /* color: #414141; */
    color: #5c5c5c;
    font-family: sans-serif;
    font-weight: 500;
    font-size: 1.09rem;
    line-height: 1.8rem;
}


.creativecard-data-btn-container{
    text-align: center;
    margin-bottom: 1rem;
}

.creativecard-btn{
    padding: 0.6rem 1.2rem;
    text-decoration: none;
    color: rgb(48, 48, 48);
    border-radius: 18px;
    position: relative;
    z-index: 12;
    background: white;
    font-family: system-ui;
    font-weight: 500;
    transition: all 0.3s ease-in-out;
    border: 2px solid #dcdcdc;
    &:hover{
        box-shadow: 0 0 4px 1px rgb(197, 197, 197);
    }
}

.creativecard-btn::before{
    padding: 0.6rem 1.2rem;
    content: '';
    position: absolute;
    z-index: -1;
    inset: -2px;
    /* background: linear-gradient(to right, #00ccffd3, #d400f9d3); */
    background: linear-gradient(to right, #a0a0a0d3, #adadadd3);
    border-radius: 20px;
}

.creativecard-data-heading-section{
    text-align: center;
    margin: 0rem 0rem 1.4rem;
    font-family: sans-serif;
    background: linear-gradient(to right, rgb(40 38 110), rgb(197 77 170));
    background-clip: text;
    color: transparent;
    font-weight: 600;
    font-size: 1.6rem;
}




@keyframes rotate {
    from{
        transform: rotate(0deg);
    }

    to{
        transform: rotate(360deg);
    }
}


@media screen and ( max-width : 1080px) {
    .creative-card-wrapper{
        width: 90vw;
    }

    .creativecard-data-container{
        padding: 1rem 0.8rem;
    }

    .creativecard-data-description-section{
        font-size: 0.8rem;
        font-weight: 400;
        font-family: sans-serif;
        color: #858585;
    }
}