body{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}


@media screen and (max-width : 280px) {
  body {
    min-width: 280px;
    overflow-x: scroll;
  }
}