.main-top-section{
    position: relative;
    top: 70px;
    background-color: #cecfcf;
    /* height: 400px; */
}


.main-container{
    /* padding-left: 1.6rem;
    padding-right: 1.6rem; */
    /* padding-top: 2.8rem; */
}