

.ira-intro-container{
    width: 100%;
    min-height: 500px;
    background-color: #f1f1f1;
}
























































