

.db-form-com-cont{
    padding: 2% 3%;
    background: linear-gradient(135deg, #ce4be8ce 0%, #207ce5c5 100%);
    margin: auto;
}

.db-form-com-wrap{
    padding: 2% 3%;
    background-color: rgb(255, 255, 255);
    min-height: 500px;
    width: 80%;
    box-shadow: 0px 0px 8px 1px #a7a7a7;
    margin: auto;
}

.db-form-com-head{
    text-align: center;
    font-size: 2rem;
    font-family: sans-serif;
    font-weight: bold;
    background: linear-gradient(45deg, #5d65c3, #aa2b93);
    color: transparent;
    background-clip: text;
    margin-bottom: 1.6rem;
}

.rmsg{
    transition: color 0.5s ease-in-out;
}

.db-form-com{
    display: flex;
    flex-wrap: wrap;
    gap: 1%;
}

.db-form-com > .db-form-com-lab-in{
    flex: 1 1 40%;
    /* width: 240px; */
}

.db-form-com > .db-form-com-lab-ta{
    flex: 1 1 100%;
}

.db-form-com-ta{
    padding: 1%;
    display: block;
    width: 86%;
    min-height: 60px;
    margin-bottom: 0.6rem;
    border: 1px solid rgba(0, 0, 0, 0.322);
    margin-top: 2px;
    &::placeholder{
        font-family: sans-serif;
    }
    &:focus{
        outline: none;
    }
}

.db-form-com > .db-form-com-btn-cont{
    flex: 1 1 100%;
    text-align: center;
}

.db-form-com  button{
    width: 40%;
    max-width: 220px;
    padding: 0.7rem 1.2rem;
    border: 2px solid rgba(0, 0, 0, 0.63);
    background-color: rgb(255, 255, 255);
    border-radius: 30px;
    font-family: sans-serif;
    font-size: 0.95rem;
    text-align: center;
    transition: all 0.3s ease-in-out;
    margin-bottom: 6px;
    cursor: pointer;
    &:hover{
        background-color: black;
        color: white;
    }
}

.db-form-com-lab{
    display: block;
    font-size: 0.76rem;
    font-weight: bold;
    font-family: sans-serif;
    text-align: left;
    color: rgb(88, 88, 88);
}

.db-form-com-input{
    border: none;
    display: block;
    border-bottom: 1px solid rgba(0, 0, 0, 0.322);
    padding: 0.8rem 0.4rem 0.3rem 0.2rem;
    color: rgb(24, 21, 61);
    margin-bottom: 1.6rem;
    width: 70%;
    min-width: 200px;
    text-align: left;
    &:focus{
        outline: none;
    }
    &::placeholder{
        color: rgb(131, 131, 131);
    }
}